/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('antd/dist/antd.css')
require('./theme.css')
//require('./src/css/global.css')
/**
 * Application Insights Setup
 */
let injectedScript = false

export const onInitialClientRender = () => {
  function addJS(jsCode) {
    var s = document.createElement(`script`)
    s.type = `text/javascript`
    s.innerText = jsCode
    document.getElementsByTagName(`head`)[0].appendChild(s)
  }
  if (!injectedScript) {
    
    injectedScript = true
  }
}

/**
 * End Application Insights
 */

export const onRouteUpdate = state => {
  const { search } = state.location
  const searchParts = search.substr(1).split('&')
  const params = {}
  searchParts.map(p => {
    const pp = p.split('=')
    params[pp[0]] = decodeURIComponent(pp[1])
    return pp
  })
  
}
